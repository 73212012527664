export const spotTypeEnum = {
  'DANWENG': {
    value: 'DANWENG',
    description: '单翁'
  },
  'SANGE': {
    value: 'SANGE',
    description: '三格化粪池'
  },
  'JIWUCHI': {
    value: 'JIWUCHI',
    description: '集污池'
  },
  'LANI': {
    value: 'LANI',
    description: '拉泥'
  },
  'GONGCE': {
    value: 'GONGCE',
    description: '公厕'
  },
  'OTHER': {
    value: 'OTHER',
    description: '其他'
  }
}

export const spotTypeList = () => {
  return [
    {
      label: '三格化粪池',
      value: 'SANGE'
    },
    {
      label: '集污池',
      value: 'JIWUCHI'
    },
    {
      label: '单翁',
      value: 'DANWENG'
    },
    {
      label: '拉泥',
      value: 'LANI'
    },
    {
      label: '公厕',
      value: 'GONGCE'
    },
    {
      label: '其他',
      value: 'OTHER'
    }
  ]
}