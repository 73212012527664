<template>
  <div style="width: 100%;height: 600px;">
    <div v-show="ifShow" style="position:absolute;bottom: 90px;left:40px;z-index: 1000">
      <span
        style="font-weight: bold;color: blue;font-size: large">位置：{{location}}&nbsp;[{{lnglat.latitude}},{{lnglat.longitude}}]</span>
    </div>
    <div :id="mapContainerId" class="map-container" style="height: 600px;"></div>
    <a-input v-model="searchKey" id='tipinput'
             style="width:360px;position:absolute;top: 90px;right:40px;z-index: 1000"
             placeholder="请输入搜索关键字"/>
  </div>
</template>

<script>
  import mapInit from './../../map/mixins/map-init'
  import UUID from '../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'

  export default {
    name: 'AmapSelectPoint',
    mixins: [mapInit],
    components: {},
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        /*地图*/
        mapContainerId: new UUID().id,
        map: null,
        currentMapStyle: 'normal',
        /*位置搜索*/
        autoCompleteComponent: null,
        placeSearchComponent: null,
        searchKey: '',
        /*标记点*/
        marker: null,
        ifShow: false,
        location: '',
        locationName: '',
        lnglat: {
          latitude: null,
          longitude: null
        }
      }
    },
    destroyed () {
      if (this.map) {
        this.map.destroy()
        this.map = null
      }
    },
    methods: {
      viewLocationInfo (location, longitude, latitude, locationName) {
        this.location = location
        this.locationName = locationName
        this.lnglat.longitude = longitude
        this.lnglat.latitude = latitude
        this.ifShow = true
        this.$nextTick(() => {
          if (this.marker) {
            this.marker.setMap(null)
          }
          /*创建一个Marker实例*/
          this.marker = new AMap.Marker({
            draggable: true,
            position: new AMap.LngLat(this.lnglat.longitude, this.lnglat.latitude)
          })
          this.marker.on('dragend', this.markerDragend)
          if (!this.map) return
          this.marker.setMap(this.map)
          this.map.setFitView(this.marker)
        })
      },
      addLocationInfo (searchKey) {
        this.searchKey = searchKey
        this.mapSearchInit()
        this.autoCompleteComponent.search(this.searchKey, (status, result) => {
          // 搜索成功时，result即是对应的匹配数据
        })
      },
      submitInfo () {
        this.$emit('pointSuccess', this.location, this.lnglat, this.locationName)
      },
      init () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.map = new AMap.Map(this.mapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/' + this.currentMapStyle,
            center: [105.685285, 37.491505],
            zoom: 12
          })
          AMap.plugin(['AMap.ToolBar', 'AMap.Scale'], () => {
            this.map.addControl(new AMap.ToolBar())
            this.map.addControl(new AMap.Scale())
          })
          if (this.type === 'add') {
            this.map.on('click', this.mapClick)
          } else {
            this.mapSearchInit()
            /*将创建的点标记添加到已有的地图实例*/
            this.marker.setMap(this.map)
            this.map.setFitView(this.marker)
          }
        })
      },
      /** 初始化搜索工具 */
      mapSearchInit () {
        let that = this
        // 绑定自动提示
        AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch'], () => {
          var autoOptions = {
            input: 'tipinput',
            city: '中宁县'
          }
          var autoCompleteComponent = new AMap.Autocomplete(autoOptions)
          // 监听选中项
          AMap.event.addListener(autoCompleteComponent, 'select', (data) => {
            console.log(data)
            if (data.poi.location !== undefined) {
              //定位到中心点
              that.map.setCenter(data.poi.location)
              that.onMarkerChange(new AMap.LngLat(data.poi.location.lng, data.poi.location.lat))
              that.lnglat.longitude = data.poi.location.lng
              that.lnglat.latitude = data.poi.location.lat
              AMap.service('AMap.Geocoder', () => { //回调函数
                //实例化Geocoder
                var geocoder = new AMap.Geocoder({
                  city: '宁夏'//城市，默认：“全国”
                })
                var lnglatXY = [data.poi.location.lng, data.poi.location.lat]//地图上所标点的坐标
                geocoder.getAddress(lnglatXY, (status, result) => {
                  if (status === 'complete' && result.info === 'OK') {
                    that.location = result.regeocode.formattedAddress
                    that.locationName = result.regeocode.addressComponent.township
                  }
                })
              })
            }
          })
          that.autoCompleteComponent = autoCompleteComponent
          // 注册placeSearch组件
          that.placeSearchComponent = new AMap.PlaceSearch({
            // city 指定搜索所在城市，支持传入格式有：城市名、citycode和adcode
            city: '中宁县'
          })
        })
      },
      /** 关键词搜索 */
      searchKeyword () {
        console.info(this.searchKey)
        this.placeSearchComponent.search(this.searchKey, (status, result) => {
          // 查询成功时，result即对应匹配的POI信息
          console.log(status)
          console.log(result)
        })
      },
      mapClick (e) {
        this.ifShow = true
        this.lnglat.longitude = e.lnglat.getLng()
        this.lnglat.latitude = e.lnglat.getLat()
        this.onMarkerChange(new AMap.LngLat(this.lnglat.longitude, this.lnglat.latitude))
        this.map.off('click', this.mapClick)
        AMap.service('AMap.Geocoder', () => { //回调函数
          //实例化Geocoder
          var geocoder = new AMap.Geocoder({
            city: '宁夏'//城市，默认：“全国”
          })
          var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()]//地图上所标点的坐标
          geocoder.getAddress(lnglatXY, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              console.log(result)
              this.location = result.regeocode.formattedAddress
              this.locationName = result.regeocode.addressComponent.township
            }
          })
        })
      },
      onMarkerChange (lngLat) {
        this.$nextTick(() => {
          if (this.marker) {
            this.marker.setMap(null)
          }
          const markerInfo = { position: lngLat }
          markerInfo.draggable = true
          this.marker = new AMap.Marker(markerInfo)
          this.marker.setMap(this.map)
          this.marker.on('dragend', this.markerDragend)
        })
      },
      // marker 拖动回调
      markerDragend (e) {
        this.lnglat.longitude = e.lnglat.getLng()
        this.lnglat.latitude = e.lnglat.getLat()
        AMap.service('AMap.Geocoder', () => { //回调函数
          //实例化Geocoder
          var geocoder = new AMap.Geocoder({
            city: '宁夏'//城市，默认：“全国”
          })
          var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()]//地图上所标点的坐标
          geocoder.getAddress(lnglatXY, (status, result) => {
            if (status === 'complete' && result.info === 'OK') {
              this.location = result.regeocode.formattedAddress
              this.locationName = result.regeocode.addressComponent.township
            }
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>